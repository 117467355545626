body,
div#root,
div#root>div:not(.reactour__popover) {
    min-height: 100vh;
}

#footer {
    height: 112px;
}

#content {
    min-height: calc(100vh - 112px);
}

.main-content {
    border-radius: var(--chakra-radii-md);
}

.chakra-heading.title:hover {
    text-decoration: underline;
}

.event-list-item {
    cursor: pointer;
}

ul > li {
    list-style-type: none;
    
}